import { get } from "lodash";

type MatchKey = string | number | symbol;

type MatchConditions<K extends MatchKey> = {
  [condition in K]?: any;
} & {
  default?: any;
};

type MatchedValue<
  K extends MatchKey,
  C extends MatchConditions<K>
> = K extends undefined
  ? C["default"]
  : C[K] extends undefined
  ? C["default"]
  : C[K];

/**
 * A JS adaptation of PHP match expression.
 * https://www.php.net/manual/en/control-structures.match.php
 *
 * Behaves live a switch statement but returns the matched value.
 * If a matched value is `undefined`, default is returned.
 *
 * @param {K} value
 * @param {MatchConditions<K>} conditions
 * @param {boolean} call if the matched value is a function, call it
 * @returns {MatchedValue<K, MatchConditions<K>>}
 */
export const match = <K extends MatchKey>(
  value: K | undefined,
  conditions: MatchConditions<K>,
  call = true
): MatchedValue<K, MatchConditions<K>> => {
  const $default = get(conditions, "default");
  let match: any;
  if (value == undefined || !value) {
    match = $default;
  } else {
    match = get(conditions, value) ?? $default;
  }

  if (call && typeof match == "function") {
    return match();
  }

  return match;
};
